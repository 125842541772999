import { fetchData } from "api/fetch";
import { FORM_TYPE, SNACKBAR_VARIANT_TYPE, SURVEY_CREATION_STEP } from "enums";
import { difference, findIndex } from "lodash";
import { checkPhoto } from "store/medias/photoCheck";
import { formsSurveyStore, mediasStore, translationsStore, uiControlStore } from "store/store";
import {
    createSurvey,
    getAllLeadersBySurvey,
    getMySurveys,
    getOneSurveyForms,
    getOneSurveyGlobal,
    getOneSurveyTemplates,
    getSurveyPreview,
    getSurveyToFill,
    getSurveys,
    putSurveyForm,
    putSurveyGeneralInfo,
    removeSurvey
    // replaySurvey
} from "store/surveys/endpoints";
import { formatSurveyThunk, formatSurveyToJson } from "store/surveys/formatters";

const actions = (set, get) => ({
    setSurveys: surveys => {
        set({ surveysList: [...surveys] });
    },

    updateSurvey: survey => {
        const index = findIndex(get().surveysList, s => s.id === survey.id);
        const splicedSurveyList = get().surveysList.splice(index, 1, survey);
        set({ surveysList: [...splicedSurveyList] });
    },

    setEditingSurveyId: id => {
        set({ editingSurveyId: id });
    },

    setEditingSurvey: survey => {
        set({ editingSurvey: { ...survey } });
    },

    setStep: step => {
        set({ step: step });
    },

    setCurrentLeader: leader => {
        set({ currentLeader: leader });
    },

    setSubStep: step => {
        set({ subStep: step });
    },

    fetchAdminSurveys: async () => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(getSurveys())
            .then(res => {
                get().setSurveys(res);
                return res;
            })
            .catch(() => setSnackbar(true, "Cannot fetch surveys", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    fetchMySurveys: () => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        fetchData(getMySurveys())
            .then(res => {
                const onGoingSurveys = res[0].ongoing;
                const answeredSurveys = res[0].answered.map(survey => {
                    return {
                        ...survey.survey,
                        ...{ isReplayable: survey.isReplayable }
                    };
                });
                const notAnsweredSurveys = res[0].notAnsweredSurveys.map(survey => {
                    return {
                        ...survey.survey,
                        ...{ isReplayable: survey.isReplayable }
                    };
                });
                set({ onGoing: [...onGoingSurveys] });
                set({ answered: [...answeredSurveys] });
                set({ notAnswered: [...notAnsweredSurveys] });
            })
            .catch(() => {
                setSnackbar(true, "Cannot fetch surveys", SNACKBAR_VARIANT_TYPE.ERROR);
            });
    },

    // TODO remove once the replaySurvey button is removed
    // postReplay: async id => {
    //     const setSnackbar = uiControlStore.getState().setSnackbar;

    //     return fetchData(replaySurvey(id))
    //         .then(res => {
    //             get().addSurvey(res);
    //             //(push(`${ANSWER_SURVEY_URL}/${res.id}`));
    //         })
    //         .catch(() =>
    //             setSnackbar(true, "The survey cannot be replayed!", SNACKBAR_VARIANT_TYPE.ERROR)
    //         );
    // },

    fetchEditingSurvey: id => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        set({ editingSurveyLoader: true });

        let editingSurvey = {};
        fetchData(getOneSurveyGlobal(id))
            .then(res => {
                editingSurvey = res;
                fetchData(getOneSurveyForms(id))
                    .then(formsInfos => {
                        editingSurvey = { ...editingSurvey, ...formsInfos };
                        fetchData(getOneSurveyTemplates(id))
                            .then(templatesInfos => {
                                editingSurvey = { ...editingSurvey, ...templatesInfos };
                                get().setEditingSurvey(editingSurvey);
                                set({ editingSurveyLoader: false });
                            })
                            .catch(() => {
                                set({ editingSurveyLoader: false });
                                setSnackbar(
                                    true,
                                    "Cannot fetch editing survey Templates",
                                    SNACKBAR_VARIANT_TYPE.ERROR
                                );
                            });
                    })
                    .catch(() => {
                        set({ editingSurveyLoader: false });
                        setSnackbar(
                            true,
                            "Cannot fetch editing survey Forms",
                            SNACKBAR_VARIANT_TYPE.ERROR
                        );
                    });
            })
            .catch(() => {
                set({ editingSurveyLoader: false });
                setSnackbar(
                    true,
                    "Cannot fetch editing survey Global",
                    SNACKBAR_VARIANT_TYPE.ERROR
                );
            });
    },

    fetchSurveyToFill: async (id, is_preview = false) => {
        set({ surveyToFill: {} });

        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(is_preview ? getSurveyPreview(id) : getSurveyToFill(id))
            .then(res => {
                if (is_preview) {
                    get().setEditingSurvey(res);
                } else {
                    set({ surveyToFill: { ...res } });
                }
            })
            .catch(err => {
                if (err.status === 401) {
                    setSnackbar(
                        true,
                        "You have already answered the form",
                        SNACKBAR_VARIANT_TYPE.ERROR
                    );
                } else {
                    setSnackbar(true, "Cannot get the survey", SNACKBAR_VARIANT_TYPE.ERROR);
                }
            });
    },

    createSurveyThunk: async survey => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        const createOneTranslation = translationsStore.getState().createOneTranslation;

        const createMediaThunk = mediasStore.getState().createMediaThunk;
        const {
            surveyTitle,
            surveyDescription,
            surveyPhoto,
            surveyTargets,
            contractsToExclude
        } = survey;
        const title = await createOneTranslation(surveyTitle, "survey.title", "survey");
        const description = await createOneTranslation(
            surveyDescription,
            "survey.description",
            "survey"
        );

        // photo
        let photo = null;
        if (surveyPhoto != null) {
            photo = await createMediaThunk(surveyPhoto);
        }
        // target
        fetchData(
            createSurvey(),
            formatSurveyToJson(survey, title, description, photo, surveyTargets, contractsToExclude)
        )
            .then(value => {
                get().setEditingSurvey(value);
                get().setEditingSurveyId(value["id"]);
                get().setStep(SURVEY_CREATION_STEP.common_core_form);
                get().setSubStep(FORM_TYPE.create_united);
            })
            .catch(() => setSnackbar(true, "Cannot create survey", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    editSurveyThunk: async (initial_survey, new_survey) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const editTranslation = translationsStore.getState().editTranslation;
        const { surveyTitle, surveyDescription, surveyTargets, contractsToExclude } = new_survey;

        await editTranslation(initial_survey.title.id, surveyTitle, "en");
        await editTranslation(initial_survey.description.id, surveyDescription, "en");

        let photo = new_survey.surveyPhoto;
        const fetchPhoto = checkPhoto(initial_survey, new_survey);
        if (fetchPhoto !== null) photo = await fetchPhoto;

        fetchData(
            putSurveyGeneralInfo(initial_survey.id),
            formatSurveyThunk(new_survey, photo, surveyTargets, contractsToExclude)
        )
            .then(res => {
                get().updateSurvey({ ...initial_survey, ...res });
                get().setEditingSurvey({ ...initial_survey, ...res });
                get().setStep(SURVEY_CREATION_STEP.summary);
            })
            .catch(() => setSnackbar(true, "Cannot update survey", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    addFormToSurvey: async (subStep, values, survey) => {
        const setSnackbar = uiControlStore.getState().setSnackbar;
        const createUnitedForm = formsSurveyStore.getState().createUnitedForm;
        const createLocalForm = formsSurveyStore.getState().createLocalForm;

        let form = null;
        let res = survey.forms ? survey.forms : [];

        let common_core_survey = survey.forms.filter(f => !f.isLocal)[0];
        let local_surveys = difference(survey.forms, common_core_survey);

        switch (subStep) {
            case FORM_TYPE.create_united:
                common_core_survey = await createUnitedForm(values);
                break;
            case FORM_TYPE.create_local:
                form = await createLocalForm(values, values.local_country, values.local_team);
                local_surveys = [...res, form];
                break;
            default:
                break;
        }

        res = [common_core_survey, ...local_surveys];
        res = res.map(r => r["@id"]);
        return fetchData(putSurveyForm(survey.id), { forms: res })
            .then(res => get().setEditingSurvey({ ...survey, ...res }))
            .catch(er => setSnackbar(true, "Cannot update survey", SNACKBAR_VARIANT_TYPE.ERROR));
    },

    deleteSurveyThunk: async id => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        return fetchData(removeSurvey(id))
            .then(() => {
                get().setEditingSurveyId(null);
                get().setEditingSurvey(null);
            })
            .catch(() => setSnackbar(true, "There is a problem"));
    },

    fetchAllLeadersBySurvey: id => {
        const setSnackbar = uiControlStore.getState().setSnackbar;

        fetchData(getAllLeadersBySurvey(id))
            .then(res => {
                set({
                    editingSurveyLeaders: res.map(leader => ({
                        value: leader,
                        label: leader
                    }))
                });
            })
            .catch(() => setSnackbar(true, "Leaders can't not collect"));
    }
});

export default actions;
