export const getSurveys = () => ({
    method: "GET",
    suffix: "/surveys?archived=false&isAReplay=false"
});

export const getSurveysWithSpecificStatus = status => ({
    method: "GET",
    suffix: `/surveys?archived=false&isAReplay=false&status=${status}`
});

export const getMySurveys = () => ({
    method: "GET",
    suffix: "/surveys/mine"
});

export const getOneSurvey = id => ({
    method: "GET",
    suffix: `/surveys/${id}`
});
// Hotfix de PROD 13/11/2023
export const getOneSurveyGlobal = id => ({
    method: "GET",
    suffix: `/surveys/${id}/global`
});

export const getOneSurveyForms = id => ({
    method: "GET",
    suffix: `/surveys/${id}/forms`
});

export const getOneSurveyTemplates = id => ({
    method: "GET",
    suffix: `/surveys/${id}/templates`
});
// Fin
export const getOneSurveyForResult = id => ({
    method: "GET",
    suffix: `/surveys/${id}/results`
});

export const getLeadersBySurvey = (id, leader) => ({
    method: "GET",
    suffix: `/surveys/${id}/${leader ? `leaders?search=${leader}` : "leader-rate"}`
});

export const getCountriesBySurvey = id => ({
    method: "GET",
    suffix: `/surveys/${id}/countries-target`
});

export const createSurvey = () => ({
    method: "POST",
    suffix: "/surveys"
});

export const putSurveyGeneralInfo = id => ({
    method: "PUT",
    suffix: `/surveys/${id}?groups[]=put_general_info`
});

export const putSurveyEmail = id => ({
    method: "PUT",
    suffix: `/surveys/${id}?groups[]=put_email`
});

export const putSurveyForm = id => ({
    method: "PUT",
    suffix: `/surveys/${id}?groups[]=put_form`
});

export const removeSurvey = id => ({
    method: "DELETE",
    suffix: `/surveys/${id}`
});

export const duplicateSurvey = id => ({
    method: "GET",
    suffix: `/surveys/${id}/clone`
});

export const startSurvey = id => ({
    method: "PUT",
    suffix: `/surveys/${id}/status?status=start`
});

export const stopSurvey = id => ({
    method: "PUT",
    suffix: `/surveys/${id}/status?status=stop`
});

export const changeEndDateSurvey = id => ({
    method: "PUT",
    suffix: `/surveys/${id}/endDate`
});

export const getSurveyToFill = id => ({
    method: "GET",
    suffix: `/surveys/${id}/my_forms`
});

export const getSurveyPreview = id => ({
    method: "GET",
    suffix: `/surveys/${id}/my_forms_preview`
});

export const getParticipationRate = id => ({
    method: "GET",
    suffix: `/surveys/${id}/united-rate`
});

export const getCountryParticipationRate = id => ({
    method: "GET",
    suffix: `/surveys/${id}/country-rate`
});

export const getTeamParticipationRate = id => ({
    method: "GET",
    suffix: `/surveys/${id}/team-rate`
});

export const getParticpationRateLeader = (id, leader_id) => ({
    method: "GET",
    suffix: `/surveys/${id}/leader-rate?leader_id=${leader_id}`
});

export const replaySurvey = id => ({
    method: "GET",
    suffix: `/surveys/${id}/replay`
});

export const sendEmail = (id, type) => ({
    method: "PUT",
    suffix: `/surveys/${id}/send?type=${type}`
});

export const haveQuestion = id => ({
    method: "GET",
    suffix: `/surveys/${id}/have_questions`
});

export const getAllLeadersBySurvey = id => ({
    method: "GET",
    suffix: `/surveys/${id}/all_leaders`
});

export const getLeaderRateExcelByCountry = (idSurvey, idCountry) => ({
    method: "GET",
    suffix: `/surveys/${idSurvey}/team-rate-excel?country=${idCountry}`
});

export const getUsersExtraction = idSurvey => ({
    method: "GET",
    suffix: `/surveys/${idSurvey}/users_information_extraction`
});

export const getSurveysByStatus = status => ({
    method: "GET",
    suffix: `/surveys?archived=false&isAReplay=false&status=${status}`
});
